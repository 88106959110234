const $ = require('jquery');

let sid;
let sadeUser;
let sadePw;
let i;
let test;

function format(num) {
  if (num < 10) { return `0${num}`; } return num;
}

function log(string) {
  const date = new Date();
  const hour = format(date.getHours());
  const min = format(date.getMinutes());
  const sec = format(date.getSeconds());
  const timeStamp = `${hour}:${min}:${sec}`;
  $('#log ul').append(`<li><span>${timeStamp}</span>${string}</li>`);
  $('#log').scrollTop(1000000);
}

function publish() {
  log('starting...');

  $('#logbox').show();
  $('table#main').find("tr[id^='uri']").each(function () {
    const uri = $(this).children()[2].textContent;
    const uriId = $(this).attr('id');
    if (sadeUser === undefined) {
      sadeUser = $('#user').val();
      sadePw = $('#password').val();
      sid = $('#sid').val();
    }

    const sidreq = sid ? `&sid=${sid}` : '';
    const target = 'data';

    $.ajax({
      type: 'POST',
      url: 'xq/publish.xq',
      data: {
        uri, target, user: sadeUser, password: sadePw, sid,
      },
      cache: false,
      success(xml) {
        test = $(xml).find('message');
        if ($(xml).find('ok').text()) {
          $(xml).find('ok').each(function () {
            log(`success: ${$(this).text()}`);
          });
          $(`#${uriId}`).removeClass('danger');
          $(`#${uriId}`).addClass('success');
          // lets check for a validation report:
          const vali = $(xml).find('status').text();
          if (vali.length > 0) {
            log(`validation status: ${vali}`);
          }
          for (i = 0; i < test.length; i++) {
            $('#log').append(test[i]);
            $('#log').append('<br/>');
          }
        } else {
          const errnote = $(xml).find('error').text();
          log(`error: ${errnote}`);
          $(`#${uriId}`).addClass('danger');
          $(`#${uriId}`).removeClass('success');
        }
      },
      error(xml) {
        const message = xml.responseXML.childNodes['0'].childNodes[1].textContent;
        log(`<span style="color:red;">ERROR: ${message}</span>`);
        $(`#${uriId}`).addClass('danger');
        $(`#${uriId}`).removeClass('success');
      },
    });
  });
}

function reset() {
  $('table#main tbody').empty();
  $('table#main tbody').append('<tr><td colspan="4" style="text-align: center;font-size:150%;color:grey;"><div id="droptarget" ondrop="drop(event)" ondragover="allowDrop(event)"></div></td></tr>');
  $('table#main').css('color', 'grey');
  $('#viewData').empty();
}

function updateOverview() {
  log('starting...');

  $('#logbox').show();
  if (sadeUser === undefined) {
    sadeUser = $('#user').val();
    sadePw = $('#password').val();
    sid = $('#sid').val();
  }

  const sidreq = sid ? `&sid=${sid}` : '';
  const target = 'data';

  $.ajax({
    type: 'POST',
    url: 'xq/publish.xq',
    data: {
      user: sadeUser, password: sadePw, sid, operation: 'overview',
    },
    cache: false,
    success(xml) {
      test = $(xml).find('message');
      if ($(xml).find('ok').text()) {
        $(xml).find('ok').each(function () {
          log(`success: ${$(this).text()}`);
        });
      }
    },

    error(xml) {
      const message = xml.responseXML.childNodes['0'].childNodes[1].textContent;
      log(`<span style="color:red;">ERROR: ${message}</span>`);
    },
  });
}

function updateIndices() {
  log('starting...');

  $('#logbox').show();
  if (sadeUser === undefined) {
    sadeUser = $('#user').val();
    sadePw = $('#password').val();
    sid = $('#sid').val();
  }

  const sidreq = sid ? `&sid=${sid}` : '';
  const target = 'data';

  $.ajax({
    type: 'POST',
    url: 'xq/publish.xq',
    data: {
      user: sadeUser, password: sadePw, sid, operation: 'indices',
    },
    cache: false,
    success(xml) {
      test = $(xml).find('message');
      if ($(xml).find('ok').text()) {
        $(xml).find('ok').each(function () {
          log(`success: ${$(this).text()}`);
        });
      }
    },

    error(xml) {
      const message = xml.responseXML.childNodes['0'].childNodes[1].textContent;
      log(`<span style="color:red;">ERROR: ${message}</span>`);
    },
  });
}

/** ******
 * GENERIC TG PUBLISH JS (Parts of)
 * copied from ~assets/publish-gui/publish-gui.js
 *
 */

// https://stackoverflow.com/a/21903119
const getUrlParameter = function getUrlParameter(sParam) {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split('&');
  let sParameterName;
  let i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
};

function forked() {
  const newpw = getUrlParameter('newpw');
  if (newpw) {
    $('#newpw').text(newpw);
    $('url').text(window.location.origin + window.location.pathname);
    $('#fork-successull').show();
  }
}

function setSid(val) {
  $('#authform').hide();
  sid = val;
  log(`got a SessionId ${sid}`);
}

function setAuth(user, pw) {
  $('#authform').hide();
  sadeUser = user;
  sadePw = pw;
  $('input[name=forkUser]').val(sadeUser);
  $('input[name=forkPassword]').val(sadePw);
  log(`got credentials to the database for user ${user}`);
}

function addTGObject(uri, title, contentType) {
  let splitUri;
  if (uri.split('.').length === 3) {
    // this is a workaround a bug in the Navigator. it drops an incorrect URI
    splitUri = `${uri.split('.')[0]}.${uri.split('.')[1]}`;
  } else {
    splitUri = uri;
  }
  let shortUri = splitUri;
  if (uri.startsWith('textgrid:')) {
    shortUri = uri.substring(9);
  }

  const uriId = `uri${shortUri.replace('\.', '_')}`;

  /* if element id already there, do not add again, blink instead */
  if ($(`#${uriId}`).length > 0) {
    $(`#${uriId}`).addClass('blink');
    setTimeout(() => { $(`#${uriId}`).removeClass('blink'); }, 1000);
  } else {
    $('table#main tbody').append(
      `<tr id="${uriId}"><td>${title.substr(0, title.indexOf(' ('))}</td><td>${contentType}</td><td>${uri}</td><td><i class="fas fa-trash"></i></td></tr>`,
    );
    $('table#main').css('color', 'black');
  }
}

function getMimeClass(contentType) {
  if (contentType.startsWith('text/tg.collection+tg.aggregation')) {
    return 'mime_collection';
  } if (contentType.startsWith('text/tg.edition+tg.aggregation')) {
    return 'mime_edition';
  } if (contentType.indexOf('tg.aggregation') != -1) {
    return 'mime_aggregation';
  } if (contentType.startsWith('text/tg.work+xml')) {
    return 'mime_work';
  } if (contentType.startsWith('text/xml')) {
    return 'mime_xml';
  } if (contentType.startsWith('text/linkeditorlinkedfile')) {
    return 'mime_tble';
  } if (contentType.startsWith('image')) {
    return 'mime_image';
  }
  return 'mime_unknown';
}

function view(string) {
  $('#view').prepend(`${string}<hr/>`);
}

function links(string) {
  $('#view').prepend(`${string}<hr/>`);
}

function allowDrop(ev) {
  ev.preventDefault();
  ev.stopPropagation();
}

function drop(ev) {
  const data = ev.dataTransfer.getData('text/html');
  const uri = $(data).attr('uri');
  const title = $(data).text();
  const type = $(data).attr('type');
  log(`${uri}|${title}|${type}`);
  addTGObject(uri, title, type);
}

/** Event listener for buttons in publish UI */

const publishButton = document.getElementById('btnPublish');

if (publishButton != null) {
  publishButton.addEventListener('click', () => {
    publish();
  });
}

const resetButton = document.getElementById('btnReset');

if (resetButton != null) {
  resetButton.addEventListener('click', () => {
    reset();
  });
}

const overviewButton = document.getElementById('btnOverview');

if (overviewButton != null) {
  overviewButton.addEventListener('click', () => {
    updateOverview();
  });
}

const indicesButton = document.getElementById('btnIndices');

if (indicesButton != null) {
  indicesButton.addEventListener('click', () => {
    updateIndices();
  });
}

document.addEventListener('click', (e) => {
  if ($(e.target.parentNode).hasClass('fa-trash')) {
    $(e.target.parentNode.parentNode.parentNode).remove();
  }
});

document.addEventListener('DOMContentLoaded', () => {
  $.ajaxSetup({ cache: false });

  $('#sid').on('change', function changeSid() {
    sid = $(this).val();
    log(sid);
  });

  $('#user').on('change', function changeUser() {
    sadeUser = $(this).val();
    log(sadeUser);
  });

  $('#password').on('change', function changePassword() {
    sadePw = $(this).val();
  });

  log('publish engine: ready');
  log(`user: ${sadeUser}`);

  $('#ajaxLoadIndicator')
    .hide() // hide it initially
    .ajaxStart(function ajaxStart() {
      $(this).show();
    })
    .ajaxStop(function ajaxStop() {
      $(this).hide();
    });

  // show drag and drop area on linux systems
  if (navigator.platform.toLowerCase().indexOf('linux') >= 0) {
    log('Linux OS detected, showing drag and drop notification');
    $('#droptarget').addClass('drop_enabled');
  } else {
    $('#droptarget').addClass('drop_disabled');
  }
});

/** function exports that allow the main module to add them to the global/window scope */

export {
  setAuth, setSid, addTGObject, getMimeClass, view, links, allowDrop, drop, log, forked,
};
