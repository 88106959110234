/* Import vendor dependencies to allow webpack to load it all together */
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import 'bootstrap';

import '@fortawesome/fontawesome-free/js/all';

import 'highlight.js';

/* Import other modules to allow webpack to load it all together */
import './neologie-interactive';
import {
  setAuth, setSid, addTGObject, getMimeClass, view, links, allowDrop, drop, log,
} from './publish';

const $ = require('jquery');
const jQuery = require('jquery');

/*
 * Initialize Sentry with DSN pointing to its project
 */
Sentry.init({
  dsn: 'https://c52348abd8b749898c04480a300ad070@dev2.discuss-data.net/6',
  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

/**
 * Sitemap
 */

$('ul.sitemap ul').hide();

$('ul.sitemap i').on('click', function () {
  if ($(this).hasClass('fa-caret-right')) {
    $(this).removeClass('fa-caret-right').addClass('fa-caret-down');
    $(this).closest('ul').find('ul').show();
  } else if ($(this).hasClass('fa-caret-down')) {
    $(this).removeClass('fa-caret-down').addClass('fa-caret-right');
    $(this).closest('ul').find('ul').hide();
  }
});

$('a > i, div > i').on('click', function () {
  if ($(this).hasClass('fa-caret-down')) {
    $(this).removeClass('fa-caret-down').addClass('fa-caret-up');
  } else if ($(this).hasClass('fa-caret-up')) {
    $(this).removeClass('fa-caret-up').addClass('fa-caret-down');
  }
});

/**
 * Interaction with elements (on click)
 */

$("a.textLink, .modelledition, [type='notAvailable'], .modal, .fa-book-link").on('click', (e) => {
  e.stopPropagation();
//  return true;
});

/**
 * RDF objects
 */

function clickLink(e, type, uri) {
  e.stopPropagation();

  $.get((`xq/${type}.xq?id=${uri}`), (data) => {
    $('.modalArea').html(data);

    $(data).modal('show');
  });
}

const personsLinks = document.querySelectorAll('.fa-user-link');

personsLinks.forEach((personsLink) => {
  const personsUri = document.getElementById(personsLink.id).getAttribute('tguri');
  personsLink.addEventListener('click', (event) => {
    clickLink(event, 'persons', personsUri);
  });
});

const booksLinks = document.querySelectorAll('.fa-book-link');

booksLinks.forEach((booksLink) => {
  const booksUri = document.getElementById(booksLink.id).getAttribute('tguri');
  booksLink.addEventListener('click', (event) => {
    clickLink(event, 'books', booksUri);
  });
});

$('#book .fa-comment').on('click', function (e) {
  e.stopPropagation();
  const target = this.getAttribute('data-target');

  $(target).modal('show');
});

$('.comp-error').on('click', function (e) {
  e.stopPropagation();

  const targetId = $(this).attr('data-target').replace('#', '');
  const target = $(`div[id=${targetId}]`);

  $(target).modal('show');
});

$('.editorial-commentary, .expan-content').on('click', function (e) {
  e.stopPropagation();
  $(this).parent().hasClass('lem') ? $(this).parent().trigger('click') : '';

  const targetId = $(this).attr('href').replace('#', '');
  const target = $(`div[id=${targetId}]`);

  $(target).modal('show');
});

/* activating loader icon if ajax is active */
const $body = $('body');

$(document).bind({
  ajaxStart() { $body.addClass('loading'); },
  ajaxStop() { $body.removeClass('loading'); },
});

$('.help').mouseover(function () {
  $(this).tooltip('show');
});

$('.result p').css('margin-bottom', '20px');
$('.result p').after('<hr/>');

$('.dropdown-submenu .submenu-link').on('click', function (e) {
  $(this).next('ul').toggle();
  e.stopPropagation();
  e.preventDefault();
});

function sendMail() {
  const subject = encodeURIComponent(document.getElementById('mailSubject').value);
  const body = encodeURIComponent(`${document.getElementById('mailContent').value}\n`);

  const link = `${'mailto:neologie@gwdg.de'
             + '?subject='}${subject
  }&body=${body}`;
  window.location.href = link;
}

/* hide overlay for small screens */

function hideOverlay() {
  $('#small-screen-warning').addClass('small-screen-hide');
}

/* disable links to printings for which there is no text (because of structural
variance) */
$('a.disabled').on('click', (e) => {
  e.stopPropagation();
  e.preventDefault();
});

$('.navbar-work-btn').on('click', function (e) {
  e.stopPropagation();
  e.preventDefault();

  if ($(this).find('svg').hasClass('fa-caret-down')) {
    $(this).removeClass('fa-caret-down').addClass('fa-caret-up');
  } else if ($(this).find('svg').hasClass('fa-caret-up')) {
    $(this).removeClass('fa-caret-up').addClass('fa-caret-down');
  }

  $(this).next('.navbar-work-subitem-container').toggle();
});

$('.overview-entry').on('click', function (e) {
  e.stopPropagation();
  e.preventDefault();

  $(this).find('.svg-inline--fa').toggleClass('fa-caret-down').toggleClass('fa-caret-up');

  $(this).next('.overview-subentry-container').children().toggleClass('overview-show');
});

/* to hightlight currently selected view */

$('.edition-menu-link').on('click', () => {
  const currentUrl = String(window.location);
  $('.edition-menu-dropdown a.dropdown-item').filter(function () {
    return currentUrl.endsWith($(this).attr('href'));
  }).addClass('current');
});

$(() => {
  function getPageName(purl) {
    let url = purl.split('/');
    url = url[url.length - 1];
    url = url.split('#');
    url = url[0];
    return url;
  }

  const currentUrl = window.location.href;
  const pageName = getPageName(currentUrl);

  $(`.navbar-nav li > a[href$='${pageName}']`).parent('li').first()
    .addClass('active');

  $('.navbar-nav > li').has('.active').first()
    .addClass('active');
});

/** Search Form Focus * */
$('#searchInput').parent().on('click', () => {
  $('#searchInput').focus();
});

/** * Sticky footer ** */

$(() => {
  function stickyFooter() {
    const footer = $('footer');
    const footerHeight = footer.outerHeight(true);
    $('body').css('margin-bottom', footerHeight);
  }

  setTimeout(stickyFooter, 200);

  $(window).resize(() => {
    setTimeout(stickyFooter, 200);
  });
});

/** * Showcase carousel animations ** */

$(() => {
  function addAnimation(parent) {
    const elements = $(parent).find('[data-animation]');

    elements.each(function () {
      const animation = $(this).data('animation');
      $(this).addClass(animation);
    });
  }

  function removeAnimation(parent) {
    const elements = $(parent).find('[data-animation]');

    elements.each(function () {
      const animation = $(this).data('animation');
      $(this).removeClass(animation);
    });
  }

  $('#showcase-carousel').on({
    'slid.bs.carousel': function () {
      addAnimation('#showcase-carousel .item.active');
    },
    'slide.bs.carousel': function () {
      setTimeout(() => {
        removeAnimation('#showcase-carousel .item.active');
      }, 600);
    },
  });
});

/** * Smooth scroll to anchor ** */

$(() => {
  $("a[href*='#']").not("[href='#'], [data-toggle], [data-slide]").on('click', function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      let target = $(this.hash);
      target = target.length ? target : $(`[name=${this.hash.slice(1)}]`);

      if (target.length) {
        $('html,body').animate({
          scrollTop: (target.offset().top - 20),
        }, 1000);

        return false;
      }
    }
  });
});

//* * jQuery Scroll to Top Control script- (c) Dynamic Drive DHTML code library: http://www.dynamicdrive.com.
//* * Available/ usage terms at http://www.dynamicdrive.com (March 30th, 09')
//* * v1.1 (April 7th, 09'):
//* * 1) Adds ability to scroll to an absolute position (from top of page) or specific element on the page instead.
//* * 2) Fixes scroll animation not working in Opera.

const scrolltotop = {
  // startline: Integer. Number of pixels from top of doc scrollbar is scrolled before showing control
  // scrollto: Keyword (Integer, or "Scroll_to_Element_ID"). How far to scroll document up when control is clicked on (0=top).
  setting: {
    startline: 100, scrollto: 0, scrollduration: 1000, fadeduration: [500, 100],
  },
  controlHTML: '<i class="fas fa-chevron-up backtotop" style="width:45px;height:45px;padding-left:12px;padding-right:12px"></i>', // HTML for control, which is auto wrapped in DIV w/ ID="topcontrol"
  controlattrs: { offsetx: 12, offsety: 12 }, // offset of control relative to right/ bottom of window corner
  anchorkeyword: '#top', // Enter href value of HTML anchors on the page that should also act as "Scroll Up" links

  state: { isvisible: false, shouldvisible: false },

  scrollup() {
    if (!this.cssfixedsupport) // if control is positioned using JavaScript
    { this.$control.css({ opacity: 0 }); } // hide control immediately after clicking it
    let dest = isNaN(this.setting.scrollto) ? this.setting.scrollto : parseInt(this.setting.scrollto);
    if (typeof dest === 'string' && jQuery(`#${dest}`).length == 1) // check element set by string exists
    { dest = jQuery(`#${dest}`).offset().top; } else dest = 0;
    this.$body.animate({ scrollTop: dest }, this.setting.scrollduration);
  },

  keepfixed() {
    const $window = jQuery(window);
    const controlx = $window.scrollLeft() + $window.width() - this.$control.width() - this.controlattrs.offsetx;
    const controly = $window.scrollTop() + $window.height() - this.$control.height() - this.controlattrs.offsety;
    this.$control.css({ left: `${controlx}px`, top: `${controly}px` });
  },

  togglecontrol() {
    const scrolltop = jQuery(window).scrollTop();
    if (!this.cssfixedsupport) this.keepfixed();
    this.state.shouldvisible = (scrolltop >= this.setting.startline);
    if (this.state.shouldvisible && !this.state.isvisible) {
      this.$control.stop().animate({ opacity: 1 }, this.setting.fadeduration[0]);
      this.state.isvisible = true;
    } else if (this.state.shouldvisible == false && this.state.isvisible) {
      this.$control.stop().animate({ opacity: 0 }, this.setting.fadeduration[1]);
      this.state.isvisible = false;
    }
  },

  init() {
    jQuery(document).ready(($) => {
      const mainobj = scrolltotop;
      const iebrws = document.all;
      mainobj.cssfixedsupport = !iebrws || iebrws && document.compatMode == 'CSS1Compat' && window.XMLHttpRequest; // not IE or IE7+ browsers in standards mode
      mainobj.$body = (window.opera) ? (document.compatMode == 'CSS1Compat' ? $('html') : $('body')) : $('html,body');
      mainobj.$control = $(`<div id="topcontrol">${mainobj.controlHTML}</div>`)
        .css({
          position: mainobj.cssfixedsupport ? 'fixed' : 'absolute', bottom: mainobj.controlattrs.offsety, right: mainobj.controlattrs.offsetx, opacity: 0, cursor: 'pointer',
        })
        .attr({ title: '' })
        .on('click', () => { mainobj.scrollup(); return false; })
        .appendTo('body');
      if (document.all && !window.XMLHttpRequest && mainobj.$control.text() != '') // loose check for IE6 and below, plus whether control contains any text
      { mainobj.$control.css({ width: mainobj.$control.width() }); } // IE6- seems to require an explicit width on a DIV containing text
      mainobj.togglecontrol();
      $(`a[href="${mainobj.anchorkeyword}"]`).on('click', () => {
        mainobj.scrollup();
        return false;
      });
      $(window).bind('scroll resize', (e) => {
        mainobj.togglecontrol();
      });
    });
  },
};

scrolltotop.init();

/* PUBLISH */
/* add functions to the global scope so that they can be called by the TGLab plugin */
window.setAuth = setAuth;
window.setSid = setSid;
window.addTGObject = addTGObject;
window.getMimeClass = getMimeClass;
window.view = view;
window.links = links;
window.allowDrop = allowDrop;
window.drop = drop;
window.log = log;
