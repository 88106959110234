/**
 * Get all necessary constant elements:
 * Lemmata, readings, the variants button or buttons, quotations and some areas.
 */
const lems = document.querySelectorAll('.lem[wits]');
const rdgs = document.getElementsByClassName('rdg-digital');
const variantsButtons = document.getElementsByClassName('variants-btn');
const buttonrow = document.querySelector('.variants-btns');
const quoteIcons = document.getElementsByClassName('quote-icon');
const quotes = document.getElementsByClassName('quoted-text');
const edition = document.getElementById('edition');
const digitalText = document.getElementById('digital-text');
const variants = document.getElementById('variants');
const svgLayer = document.querySelector('.svg-layer');

/**
 * This functions sets the dimensions of the SVG-layer equally to those of the container of the digital edition.
 * This allows for correct positioning of paths between lemmata and readings.
 * @function
 */
const setSvgLayer = function setSvgLayer() {
  if (svgLayer != null) {
    svgLayer.style.top = `${digitalText.offsetTop}px`;
    svgLayer.style.height = `${edition.offsetHeight}px`;
    svgLayer.style.left = `${edition.offsetLeft}px`;
    svgLayer.style.width = `${edition.offsetWidth}px`;
  }
};

/**
 * This function resizes the edition container to make sure that the footer
 * stays at the bottom of the page. This is necessary where a large
 * number of readings is opened, as they may exceed the footer in these cases.
 * @function
 */
const resizeEdition = function resizeEdition() {
  const openRdgs = document.getElementsByClassName('show');
  let lowestRdg = false;
  for (let i = 0; i < openRdgs.length; i += 1) {
    lowestRdg === false
    ? lowestRdg = openRdgs[i]
    : openRdgs[i].offsetTop
    > lowestRdg.offsetTop
    ? lowestRdg = openRdgs[i]
    : lowestRdg;
  }
  digitalText.style.height = `${lowestRdg.offsetTop}px`;
  setSvgLayer();
};

/**
 * This function vertically positions a span containing a reading, either at the
 * same height as the corresponding lemma, or at a different height when it would
 * overlap other readings.
 *
 * @function
 * @param {object} rdg A span containing a reading.
 */
const positionRdg = function positionRdg(rdg) {
  const openRdgs = document.querySelectorAll('.show');
  const lemmaId = rdg.getAttribute('target-lem').replace('#', '');
  const lemma = document.getElementById(lemmaId);
  let rdgPos = lemma.offsetTop + buttonrow.offsetHeight;
  const rdgBottom = rdgPos + rdg.offsetHeight;

  for (openedRdg of openRdgs) {
    if (openedRdg != rdg) {
      const openedRdgTop = parseFloat(openedRdg.style.top);
      const openedRdgBottom = openedRdgTop + openedRdg.offsetHeight;
      rdgPos >= openedRdgTop - 5 && rdgPos <= openedRdgBottom + 5 || rdgPos <= openedRdgTop - 5 && rdgBottom >= openedRdgBottom + 5
        ? rdgPos = openedRdgBottom + 5
        : rdgPos;
    }
  }
  rdg.style.top = `${rdgPos}px`;
};

/**
 * This function draws a SVG-path between a lemma and a corresponding reading
 * and displays it.
 *
 * @function
 * @param {object} lemma A span containing a lemma.
 * @param {object} rdg A span containing a reading.
 */
const drawPath = function drawPath(lemma, rdg) {
  const rdgId = rdg.id;
  const path = document.querySelector(`[target-rdg='#${rdgId}']`);

  const pathXStart = lemma.offsetWidth >= (digitalText.offsetWidth - 35) ? (35)
    : (lemma.offsetLeft + lemma.offsetWidth / 2) >= digitalText.offsetWidth ? 60
      : (lemma.offsetLeft + lemma.offsetWidth / 2);
  const pathYStart = lemma.offsetTop + lemma.offsetHeight - 1;
  const pathXEnd = variants.offsetLeft - lemma.offsetParent.offsetLeft + 15;
  const pathYEnd = rdg.offsetTop - buttonrow.offsetTop - buttonrow.offsetHeight + rdg.offsetHeight / 2;

  const pathMod = pathYStart + 10 < pathYEnd ? `q 3 0 3 3 V ${pathYEnd} q 0 3 3 3`
    : pathYStart - 10 > pathYEnd ? `q 3 0 3 -3 V ${pathYEnd} q 0 -3 3 -3`
      : '';

  path.setAttribute('d', `M ${pathXStart} ${pathYStart} q 0 3 3 3 H ${digitalText.offsetWidth}${pathMod} H ${pathXEnd}`);
  path.classList.add('visible');
};

/**
 * This function opens a reading, positions it, highlights the corresponding lemma
 * and draws a SVG-path between the two. This function is called when one of the
 * variant buttons is clicked.
 *
 * @function
 * @param {object} rdg A span containing a reading.
 */
const openRdg = function openRdg(rdg) {
  const lemmaId = rdg.getAttribute('target-lem').replace('#', '');
  const lemma = document.getElementById(lemmaId);
  const path = document.querySelector(`.rdg-path[target-rdg='#${rdg.id}']`);

  lemma.classList.add('highlight-lem');
  positionRdg(rdg);
  rdg.classList.add('show');
  drawPath(lemma, rdg);
  rdg.offsetTop > edition.offsetHeight ? resizeEdition() : '';
};

/**
 * This function closes a reading when the reading, its corresponding lemma or
 * the variant button is clicked.
 *
 * @function
 * @param {object} rdg A span containing a reading.
 */
const closeRdg = function closeRdg(rdg) {
  const lemmaId = rdg.getAttribute('target-lem').replace('#', '');
  const lemma = document.getElementById(lemmaId);
  const path = document.querySelector(`.rdg-path[target-rdg='#${rdg.id}']`);

  /** do not remove highlighting from corresponding lemma if there is another
     * open reading corresponding to that lemma.
     */
  document.querySelectorAll(`.show[target-lem='#${lemmaId}']`).length > 1 ? '' : lemma.classList.remove('highlight-lem');
  rdg.classList.remove('show');
  rdg.style.top = '';
  path.classList.remove('visible');
};

/**
 * This function handles the highlighting of a lemma when clicked and calls the
 * display of any relevant readings, as well as drawing the paths between them.
 *
 * @function
 * @param {object} lem A span containing a lemma.
 */
const openLemma = function openLemma(lem) {
  const { id } = lem;
  const relevantRdgs = document.querySelectorAll(`.rdg-digital[target-lem='#${id}']`);

  lem.classList.add('highlight-lem');
  for (rdg of relevantRdgs) {
    positionRdg(rdg);
    rdg.classList.add('show');
    drawPath(lem, rdg);
  }
  rdg.offsetTop > edition.offsetHeight ? resizeEdition() : '';
};

/**
 * This function closes a lemma and all its corresponding readings.
 *
 * @function
 * @param {object} lem A span containing a lemma.
 */
const closeLemma = function closeLemma(lem) {
  const { id } = lem;
  const relevantRdgs = document.querySelectorAll(`.rdg-digital[target-lem='#${id}']`);

  lem.classList.remove('highlight-lem');
  for (rdg of relevantRdgs) {
    closeRdg(rdg);
  }
};

/**
 * This is a wrapper function, checking whether a clicked lemma is open or closed.
 *
 * @function
 */
const toggleLemma = function toggleLemma() {
  this.classList.contains('highlight-lem') ? closeLemma(this) : openLemma(this);
};

/**
 * This function opens or closes all readings of a particular witness.
 * If there are any closed readings corresponding to that witness, all remaining
 * closed readings are opened. Otherwise, all readings corresponding to that
 * witness will close.
 *
 * @function
 */
const toggleAllLemmata = function toggleAllLemmata() {
  const buttonId = this.id;
  const witness = buttonId.charAt(buttonId.length - 1);
  const openRdgs = document.querySelectorAll(`.show.rdg-digital[wits*='${witness}']`);
  const closedRdgs = document.querySelectorAll(`:not(.show).rdg-digital[wits*='${witness}']`);

  if (closedRdgs.length > 0) {
    for (const rdg of closedRdgs) { openRdg(rdg); }
  } else {
    for (rdg of openRdgs) { closeRdg(rdg); }
  }
};

/**
 * This function highlights a lemma on mouseover, its correspoding reading or
 * readings, as well as the path or paths between them.
 *
 * @function
 */
const mouseOverLem = function mouseOverLem() {
  const { id } = this;
  const rdg = document.querySelector(`[target-lem='#${id}']`);
  const path = document.querySelector(`.rdg-path[target-lem='#${id}']`);

  this.classList.toggle('highlight-lem-hover');
  rdg.classList.toggle('highlight-show');
  path.classList.toggle('highlight-show');
};

/**
 * This function highlights a reading on mouseover, as well as its corresponding
 * lemma and the path between the two.
 *
 * @function
 */
const mouseOverRdg = function mouseOverRdg() {
  const { id } = this;
  const lemId = this.getAttribute('target-lem').replace('#', '');
  const lem = document.getElementById(lemId);
  const path = document.querySelector(`.rdg-path[target-rdg='#${id}']`);

  this.classList.toggle('highlight-show');
  lem.classList.toggle('highlight-lem-hover');
  path.classList.toggle('highlight-show');
};

/**
 * This function sets the dimensions of the SVG-layer, as well as the position
 * of any opened readings and their corresponding paths, when the screen is
 * resized.
 *
 * @function
 */
const onScreenResize = function onScreenResize() {
  const openRdgs = document.querySelectorAll('.show');

  setSvgLayer();
  for (rdg of openRdgs) {
    const lemmaId = rdg.getAttribute('target-lem').replace('#', '');
    const lemma = document.getElementById(lemmaId);
    positionRdg(rdg);
    drawPath(lemma, rdg);
  }
};

/**
 * This function handles the highlighting of a quote when clicked and calls the
 * display of the full quotation.
 *
 * @function
 * @param {object} icon A span containing a quoteS.
 */
const openQuote = function openQuote(quote) {
  const { id } = quote;
  const fullQuote = document.querySelector(`.quoted-text[target-node='#${id}']`);

  quote.classList.add('highlight-quote');
  fullQuote.style.top = `${quote.offsetTop}px`;
  fullQuote.classList.add('show');
  fullQuote.offsetTop > edition.offsetHeight ? resizeEdition() : '';
};

/**
 * This function closes a quote and all the full quotation.
 *
 * @function
 * @param {object} quote A span containing a quote.
 */
const closeQuote = function closeQuote(quote) {
  const { id } = quote;
  const fullQuote = document.querySelector(`.quoted-text[target-node='#${id}']`);

  quote.classList.remove('highlight-quote');
  fullQuote.classList.remove('show');
};

/**
 * This is a wrapper function, checking whether a clicked quote is open or closed.
 *
 * @function
 */
const toggleQuoteIcon = function toggleQuoteIcon() {
  const quote = this.parentElement;
  quote.classList.contains('highlight-quote') ? closeQuote(quote) : openQuote(quote);
};

/**
 * This function closes a long quotation when clicked.
 *
 * @function
 * @param {object} fullquote A span containing a longer version of a quote.
 */
const closeFullQuote = function closeFullQuote(fullquote) {
  const { id } = fullquote;
  const quote = document.querySelector(`.quote[target-node='${id}']`);

  fullquote.classList.remove('show');
  quote.classList.remove('highlight-quote');
};

/**
 * This function highlights a short quote and its corresponding longer version
 * on mouseover over the quotation icon.
 *
 * @function
 */
const mouseOverQuoteIcon = function mouseOverQuoteIcon() {
  const { id } = this.parentElement;
  const fullQuote = document.querySelector(`.quoted-text[target-node='#${id}']`);
  console.log(fullQuote);

  this.parentElement.classList.toggle('highlight-quote-hover');
  fullQuote.classList.toggle('highlight-quote-hover');
};

/**
 * This function highlights a short quote and its corresponding longer version
 * on mouseover over the longer quotation.
 *
 * @function
 */
const mouseOverFullQuote = function mouseOverFullQuote() {
  const { id } = this;
  const quote = document.querySelector(`.quote[target-node='${id}']`);

  this.classList.toggle('highlight-quote-hover');
  quote.classList.toggle('highlight-quote-hover');
};

document.addEventListener('DOMContentLoaded', setSvgLayer);
window.addEventListener('resize', onScreenResize);

for (let i = 0; i < lems.length; i += 1) {
  lems[i].addEventListener('click', toggleLemma, false);
  lems[i].addEventListener('mouseenter', mouseOverLem, false);
  lems[i].addEventListener('mouseleave', mouseOverLem, false);
}

for (let i = 0; i < rdgs.length; i += 1) {
  rdgs[i].addEventListener('click', function () { closeRdg(this); }, false);
  rdgs[i].addEventListener('mouseenter', mouseOverRdg, false);
  rdgs[i].addEventListener('mouseleave', mouseOverRdg, false);
}

for (let i = 0; i < variantsButtons.length; i += 1) {
  variantsButtons[i].addEventListener('click', toggleAllLemmata, false);
}

for (let i = 0; i < quoteIcons.length; i += 1) {
  quoteIcons[i].addEventListener('click', toggleQuoteIcon, false);
  quoteIcons[i].addEventListener('mouseenter', mouseOverQuoteIcon, false);
  quoteIcons[i].addEventListener('mouseleave', mouseOverQuoteIcon, false);
}

for (let i = 0; i < quotes.length; i += 1) {
  quotes[i].addEventListener('click', function () { closeFullQuote(this); }, false);
  quotes[i].addEventListener('mouseenter', mouseOverFullQuote, false);
  quotes[i].addEventListener('mouseleave', mouseOverFullQuote, false);
}
